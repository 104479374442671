<template>
  <layout-banner>
    <b-row class="px-2 pb-5 vh100">
      <b-col cols="12" class="content-center">
        <LevantamientoPrenda :esLevPrendaWeb="true" />
      </b-col>
    </b-row>
  </layout-banner>
</template>

<script>
import mixinsEvents from "@/app/shared/mixins/mixins-events";

// COMPONENTS
import LayoutBanner from "@/app/shared/layouts/Banner.vue";
import LevantamientoPrenda from "./LevantamientoPrenda.vue";

export default {
  mixins: [mixinsEvents],
  components: { LayoutBanner, LevantamientoPrenda },
};
</script>
